import * as React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import Dar from "@mui/icons-material/DoubleArrow"

const breadData = [
  {
    id: 1,
    ink: "/",
    label: "HOME",
  },
  {
    id: 3,
    ink: "/case-study/bandwidth-support",
    label: "CASE STUDY",
  },
]

const RiskReduction = () => (
  <Layout>
    <Seo title="Bandwidth Support | Case Study" />
    <div className="breadcrumbs">
      <nav className="container-fluid p-sides-med">
        <ul>
          {breadData.map(breadcrumb => (
            <li key={breadcrumb.id}>
              <Link to={breadcrumb.ink}>{breadcrumb.label}</Link>
            </li>
          ))}
        </ul>
      </nav>
    </div>
    <div className="grid--bg--bandwidth-cs">
      <section className="section-bio pt-5">
        <div className="container">
          <div className="row">
            <div className="pcsy">
              <div className="section-col-cs">
                <h4>CASE STUDY</h4>
                <hr />
              </div>
              <div className="col-sm-6">
                <h1>BANDWIDTH SUPPORT</h1>
                <h2 className="sec--sub-header">Reduce Troubleshooting from Weeks to Minutes</h2>
                <h3>Supporting Operations and Security Teams</h3>
                <p>
                Because Vigilant provides security in depth, we do more than stop threats. Our services decrease the mean time to resolution for numerous operational issues because troubleshooting is seamless with the visibility Vigilant brings.
               </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    
      <section className="section-blocks-6">
      <div className="section-col-1 pt-5">
        <div className="container">
          <div className="side-binder">
            <h4>PROBLEM</h4>
            <h3>BANDWIDTH COMPLICATIONS</h3>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="csy">
          <p>
          A large healthcare conglomerate approached us because they were having significant bandwidth complications across all their environments. Their teams had been working on this issue for over a month with no result and the bandwidth kept getting slower. By the time they engaged us the issues were becoming so severe that they were considering moving to temporary paper operations because of the degradation of their systems.
          </p>
        </div>
      </div>
    </section>
    <section className="section-blocks-6">
      <div className="section-col-1 pt-5">
        <div className="container">
          <div className="side-binder">
            <h4>OUTCOME</h4>
            <h3>VIGILANT NETWORK DETECTION</h3>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="csy">
          <p>
          Vigilant deployed MNDR at six hospital locations within 24 hours of communication with the client. Hospital networks are increasingly complex with significant segmentation and traffic taking nonstandard routes. To complicate matters further, technology isn’t always in control of the teams managing it due to third party technology integrations. In many cases, these technologies are used to monitor vitals and dispense medications. However, once we were fully operational, we knew almost immediately what the issue was. Using the visibility from our MNDR solution, we found Conficker malware on Windows 98 systems. The malware had propagated throughout their network and was causing bandwidth slowdowns in the internal operating environment of their facilities.           </p>
          <p>
          Our client was not aware of any Windows 98 installations since policy dictated it was not allowed in any of their locations for compliance reasons—and well it’s 2022. But why didn’t they know? They were relying on the certification supplied by their vendors to prove compliance, and they’re vendors didn’t tell them. Vigilant’s detection and analysis discovered the noncompliant systems and we were able to provide forensic evidence to show that Windows 98 systems were indeed in the hospitals’ infrastructure and were infected with Conficker. The worst part? The infected systems were heart monitors!
          </p>
          <p>
          The client was dealing with killware because the third party technology provider created a splash screen on the monitor to disguise the unsupported OS in order to cut costs. Without this level of visibility and analysis, normally, you would have to troubleshoot everything, all along the system, across multiple devices. It would take hours, days, sometimes weeks. But because we had the ability to see what was happening inside this environment, we were able to identify very quickly what was happening and causing a major problem inside this organization. It helped save lives and a significant amount of time…
          </p>
          <p><strong>Time saved for the client’s IT Ops team.</strong></p>
        </div>
      </div>
    </section>
    </div>

    <div className="text-center pb-5">
      <Link to="/contact/" className="nav-section--cta-btn">
        <div className="d-flex align-items-center">
          <span
            style={{ marginLeft: "25px" }}
            className="nav-section--cta-title pr-5"
          >
            schedule a call
          </span>
          <Dar className="chevy" sx={{ fontSize: 15, marginLeft: 2 }} />
        </div>
      </Link>
    </div>
    <div className="breadcrumbs">
      <nav className="container-fluid p-sides-med">
        <ul>
          {breadData.map(breadcrumb => (
            <li key={breadcrumb.id}>
              <Link to={breadcrumb.ink}>{breadcrumb.label}</Link>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  </Layout>
)

export default RiskReduction
